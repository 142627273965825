

/*=================================================================
  Pricing section
==================================================================*/

.pricing-table {
  .pricing-item {
    margin-bottom:15px;
    border-radius: 3px;
    padding-bottom: 40px;
    text-align: center;
    background: #f9f9f9;
    a.btn-main {
      text-transform: uppercase;
      margin-top: 20px;
    }
    ul {
      padding :20px 0 10px;
    }
     li {
      font-weight: 400;
      padding: 10px 0;
      color:#666;
      i {
        margin-right: 6px;
      }
    }
  }
  .price-title {
    padding: 30px 0 20px;
    text-align: center;
    border-top-right-radius: 3px;
    border-top-left-radius:3px;
    color:$white;
     > h3 {
      color: $white;
      font-weight: 700;
      margin: 0 0 5px;
      font-size: 15px;
      text-transform: uppercase;
    }
    > p {
      color: $white;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      margin-top: 5px;
      width: 60%;
      margin:0 auto;
    }
    .value {
      font-size: 50px;
      padding: 10px 0;
    }
  }
 
}


