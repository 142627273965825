@import url('https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600,700');
body {
  line-height: 1.4;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
}
p {
  font-family: $primary-font;
  color: #9a9a9a;
  font-size:15px;
}
h1,h2,h3,h4,h5,h6 {
	font-family: $secondary-font;
	font-weight: 600;
}