@import url("https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600,700");
body {
  line-height: 1.4;
  font-family: "Titillium Web", sans-serif;
  -webkit-font-smoothing: antialiased;
}

p {
  font-family: "Titillium Web", sans-serif;
  color: #9a9a9a;
  font-size: 15px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
}

/*=== MEDIA QUERY ===*/
.no-padding {
  padding: 0 !important;
}

.inline-block {
  display: inline-block;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

figure {
  margin: 0;
}

a {
  transition: all .3s ease-in 0s;
}

iframe {
  border: 0;
}

a, a:focus, a:hover {
  text-decoration: none;
  outline: 0;
}

a:focus, a:hover {
  color: #f0326b;
}

.btn-main, .btn-main-sm {
  background-color: #21D6BF;
  background-image: linear-gradient(100deg, #f9643d, #fe2a77);
  color: #fff;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 10px 40px;
  text-transform: uppercase;
  border: 1px solid transparent;
  border-radius: 2px;
}

.btn-main:hover, .btn-main-sm:hover {
  color: #fff;
}

.btn-main-sm {
  padding: 8px 20px;
  font-size: 12px;
}

.btn-transparent {
  color: #fff;
  border: 1px solid #fff;
  display: inline-block;
  font-size: 13px;
  letter-spacing: 1px;
  padding: 10px 40px;
  text-transform: uppercase;
}

.btn-transparent:hover {
  color: #fff;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.btn:focus {
  color: #ddd;
}

#home {
  position: relative;
}

.section {
  padding: 100px 0;
}

@media (max-width: 480px) {
  .section {
    padding: 40px 0;
  }
}

.padding-0 {
  padding: 0;
}

@media (max-width: 768px) {
  .padding-0 {
    padding: 0 15px;
  }
}

.section-sm {
  padding: 70px 0;
}

.section-xs {
  padding: 40px 0;
}

.btn.active:focus, .btn:active:focus, .btn:focus {
  outline: 0;
}

#preloader {
  background: #f0326b;
  height: 100%;
  opacity: 1;
  filter: alpha(opacity=100);
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
}

.preloader {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.preloader .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.preloader .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.preloader .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.preloader .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.preloader .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.preloader .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.preloader .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.preloader .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.preloader .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.preloader .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.preloader .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.preloader .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.preloader .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.preloader .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.preloader .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.preloader .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.preloader .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.preloader .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.preloader .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.preloader .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.preloader .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.preloader .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.preloader .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.preloader .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.title {
  padding-bottom: 50px;
  text-align: center;
}

.title h2 {
  font-size: 35px;
  color: #232323;
  text-transform: capitalize;
  margin-bottom: 8px;
  font-weight: 700;
}

.title h4 {
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
  letter-spacing: 3.3px;
  text-transform: uppercase;
}

.title p {
  color: #848484;
  width: 50%;
  margin: 0 auto;
}

@media (max-width: 480px) {
  .title p {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .title p {
    width: 80%;
  }
}

.title .border {
  border: none;
  display: inline-block;
  border-radius: 3px;
  height: 6px;
  position: relative;
  width: 90px;
  margin-bottom: 10px;
}

.bg-gray {
  background: #f9f9f9;
}

.color-gray {
  color: #777;
}

.shadow {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.34);
}

.gradient, .title .border, .service-2 .service-item i {
  background-image: linear-gradient(100deg, #f9643d, #fe2a77);
}

.header-bradcrumb {
  background: transparent;
}

.header-bradcrumb a, .header-bradcrumb .active {
  color: #cfcfcf;
  font-weight: 200;
  font-family: "Titillium Web", sans-serif;
}

.header-bradcrumb a:hover {
  color: #f0326b;
}

.slick-slide {
  outline: none;
}

.commonTab .nav-tabs {
  border: 1px solid transparent;
  text-align: center;
}

.commonTab .nav-tabs .nav-item {
  margin: 0 3px;
}

.commonTab .nav-tabs .nav-item .nav-link {
  background: #f9f9f9;
  color: #666;
  text-transform: capitalize;
  border-radius: 0;
}

.commonTab .nav-tabs .nav-item .nav-link.active {
  border: 1px solid #f0326b;
  background: #f0326b;
  color: #fff;
}

.commonTab .nav-tabs .nav-item .nav-link:hover {
  border: 1px solid #f0326b;
  border-radius: 0;
  background: #f0326b;
  color: #fff;
}

.commonTab .tab-content {
  padding-top: 30px;
}

.bg-pricing {
  background: url("../images/pricing-bg.jpg");
  background-size: cover;
}

.single-page-header {
  background: #1f2532;
  padding: 50px 0;
  text-align: center;
  color: #fff;
  position: relative;
}

.single-page-header .breadcrumb {
  background: transparent;
  display: inline-block;
}

.single-page-header .breadcrumb li {
  display: inline-block;
}

.single-page-header .breadcrumb li a {
  color: #f0326b;
}

.navigation {
  transition: .3s all;
  background: #1f2532;
}

.navigation .navbar-nav > li.active a {
  color: #fff;
}

.navigation .navbar-nav > li > a {
  color: #707d8f;
  font-size: 15px;
  line-height: 26px;
  padding: 20px 15px !important;
  transition: .2s ease-in-out 0s;
}

@media (max-width: 480px) {
  .navigation .navbar-nav > li > a {
    padding: 8px 0 !important;
  }
}

@media (max-width: 768px) {
  .navigation .navbar-nav > li > a {
    padding: 8px 0 !important;
  }
}

.navigation .navbar-nav > li > a:hover, .navigation .navbar-nav > li > a:active, .navigation .navbar-nav > li > a:focus {
  background: none;
  color: #f0326b;
}

.navigation .navbar-toggler {
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  outline: none;
}

.navigation .nav .open > a {
  background: none;
}

.navigation .dropdown {
  padding: 0;
}

.navigation .dropdown .dropdown-item {
  padding: 8px 15px;
  color: #fff;
  font-size: 13px;
}

.navigation .dropdown .dropdown-item:hover {
  background: transparent;
  color: #f0326b;
}

.navigation .dropdown .dropdown-menu {
  border-radius: 0;
  transform-origin: top;
  animation-fill-mode: forwards;
  transform: scale(1, 0);
  background-color: #000;
  display: block;
  opacity: 0;
  transition: opacity 0.3s ease-in-out,-webkit-transform 0.3s;
  transition: transform 0.3s,opacity 0.3s ease-in-out;
  transition: transform 0.3s,opacity 0.3s ease-in-out,-webkit-transform 0.3s;
}

.navigation .dropdown:hover .dropdown-menu {
  transition: opacity 0.3s ease-in-out,-webkit-transform 0.3s;
  transition: transform 0.3s,opacity 0.3s ease-in-out;
  transition: transform 0.3s,opacity 0.3s ease-in-out,-webkit-transform 0.3s;
  transform: scale(1, 1);
  opacity: 1;
}

.navbar-brand {
  height: auto;
  padding: 8px 0;
}

.navbar-brand h1 {
  margin: 0;
}

.hero-area {
  padding: 150px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1f2532;
}

@media (max-width: 480px) {
  .hero-area {
    padding: 80px 0;
  }
}

.hero-area .block {
  color: #fff;
  padding-left: 30px;
}

@media (max-width: 480px) {
  .hero-area .block {
    padding: 0;
  }
}

.hero-area .block h2 {
  font-size: 48px;
  margin-bottom: 20px;
}

.hero-area .block p {
  color: #707d8f;
  margin-bottom: 20px;
}

.hero-area .block .list-inline {
  margin-top: 50px;
}

.hero-area .block .btn-main, .hero-area .block .btn-main-sm {
  margin-right: 8px;
}

.video-player iframe {
  width: 100%;
  height: 100%;
}

.video-player .play-icon {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

.video-player .play-icon:hover i {
  transform: scale(1.1);
}

.video-player .play-icon i {
  margin-left: 3px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
  background: #fff;
  color: #f0326b;
  line-height: 80px;
  font-size: 30px;
  margin-left: 5px;
}

.hero-area-video {
  height: 100vh;
  position: inherit !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-area-video .block {
  color: #fff;
  text-align: center;
}

.hero-area-video .block h1 {
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 20px;
}

.hero-area-video .block p {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 3px;
  margin-bottom: 20px;
}

.hero-area-video .block .btn-main, .hero-area-video .block .btn-main-sm {
  margin-top: 20px;
}

.dark-bg {
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}

/*=================================================================
  About us section
==================================================================*/
.about {
  background: #fff;
}

.about h2 {
  font-size: 40px;
  font-weight: bold;
  margin-top: 0;
}

@media (max-width: 992px) {
  .about h2 {
    font-size: 25px;
  }
}

.about h4 {
  font-size: 14px;
  color: #f0326b;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.about p {
  line-height: 1.6;
  margin-top: 20px;
}

.about .content {
  padding: 50px 0;
}

@media (max-width: 992px) {
  .about .content {
    padding: 5px 0;
  }
}

/*=================================================================
  About us 2 section
==================================================================*/
.about-2 {
  background: #F7F7F7;
}

.about-2 h2 {
  position: relative;
}

.about-2 h2:before {
  position: absolute;
  left: -15%;
  top: 18px;
  content: '';
  display: inline-block;
  width: 12%;
  height: 3px;
  background-color: #ffa535;
  background-image: linear-gradient(100deg, #f9643d, #fe2a77);
  background-image: linear-gradient(100deg, #f9643d, #fe2a77);
}

@media (max-width: 768px) {
  .about-2 h2:before {
    display: none;
  }
}

/*=================================================================
 Call To Action 1
==================================================================*/
.call-to-action {
  padding: 100px 0;
  background: url("../images/call-to-action/call-to-action-bg-2.jpg");
  background-size: cover;
  color: #fff;
}

.call-to-action h2 {
  line-height: 1.5;
}

.call-to-action p {
  color: #fff;
}

.call-to-action .btn-main, .call-to-action .btn-main-sm {
  margin-top: 20px;
}

/*=================================================================
 Call To Action 2
==================================================================*/
.call-to-action-2 {
  padding: 100px 0;
  background-image: url("../images/call-to-action/call-to-action-bg.jpg");
  background-size: cover;
  color: #fff;
}

.call-to-action-2 h2 {
  line-height: 1.5;
}

.call-to-action-2 p {
  color: #fff;
}

.call-to-action-2 .btn-main, .call-to-action-2 .btn-main-sm {
  margin-top: 20px;
}

/*=================================================================
  Contact
  ==================================================================*/
.contact-form {
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .contact-form {
    margin-top: 20px;
  }
}

.contact-form .form-control {
  background-color: transparent;
  border: 1px solid #dedede;
  box-shadow: none;
  height: 45px !important;
  color: #0c0c0c;
  height: 38px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  border-radius: 3px;
}

.contact-form input:hover,
.contact-form textarea:hover,
.contact-form #contact-submit:hover {
  border-color: #f0326b;
}

.contact-form #contact-submit {
  border: none;
  padding: 15px 0;
  width: 100%;
  margin: 0;
  background: #f0326b;
  color: #fff;
  border-radius: 0;
  cursor: pointer;
}

.contact-form textarea.form-control {
  padding: 10px;
  height: 120px !important;
  outline: none;
}

.contact-details .contact-short-info {
  margin-top: 15px;
}

.contact-details .contact-short-info li {
  margin-bottom: 6px;
}

.social-icon li {
  display: inline-block;
  margin-right: 10px;
}

.social-icon li a {
  display: block;
  height: 30px;
  width: 30px;
  text-align: center;
  color: #f0326b;
}

.social-icon li a i {
  display: inline-block;
  font-size: 20px;
  line-height: 50px;
  margin: 0;
}

.error {
  display: none;
  padding: 10px;
  color: #D8000C;
  border-radius: 4px;
  font-size: 13px;
  background-color: #FFBABA;
}

.success {
  background-color: #6cb670;
  border-radius: 4px;
  color: #fff;
  display: none;
  font-size: 13px;
  padding: 10px;
}

.map {
  position: relative;
}

.map #map {
  height: 400px;
  color: white;
}

.contact-meta-block {
  margin-top: 30px;
  padding: 40px 0;
  min-height: 250px;
  text-align: center;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.05);
}

.contact-meta-block > i {
  display: block;
  font-size: 50px;
  margin-bottom: 20px;
}

/*=================================================================
  Item counter section
==================================================================*/
.counters-item {
  padding-bottom: 30px;
  color: #444;
  position: relative;
}

.counters-item.kill-border:before {
  background: none;
}

.counters-item:before {
  background: #e5e5e5;
  content: "";
  margin-top: -27.5px;
  height: 55px;
  width: 1px;
  position: absolute;
  right: -15px;
  top: 50%;
}

.counters-item > div {
  margin: 6px 0;
}

.counters-item span {
  display: inline;
  font-size: 35px;
  font-weight: 700;
  line-height: 50px;
}

.counters-item i {
  font-size: 40px;
  display: inline-block;
  color: #f0326b;
}

.counters-item h3 {
  font-size: 18px;
  margin: 0;
  color: #666;
}

/*=================================================================
  Pricing section
==================================================================*/
.pricing-table .pricing-item {
  margin-bottom: 15px;
  border-radius: 3px;
  padding-bottom: 40px;
  text-align: center;
  background: #f9f9f9;
}

.pricing-table .pricing-item a.btn-main, .pricing-table .pricing-item a.btn-main-sm {
  text-transform: uppercase;
  margin-top: 20px;
}

.pricing-table .pricing-item ul {
  padding: 20px 0 10px;
}

.pricing-table .pricing-item li {
  font-weight: 400;
  padding: 10px 0;
  color: #666;
}

.pricing-table .pricing-item li i {
  margin-right: 6px;
}

.pricing-table .price-title {
  padding: 30px 0 20px;
  text-align: center;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  color: #fff;
}

.pricing-table .price-title > h3 {
  color: #fff;
  font-weight: 700;
  margin: 0 0 5px;
  font-size: 15px;
  text-transform: uppercase;
}

.pricing-table .price-title > p {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 5px;
  width: 60%;
  margin: 0 auto;
}

.pricing-table .price-title .value {
  font-size: 50px;
  padding: 10px 0;
}

/*=================================================================
  Portfolio section
==================================================================*/
.portfolio-filter {
  margin-bottom: 40px;
  text-align: center;
}

.portfolio-filter button {
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  margin: 0 10px;
  padding: 8px 5px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  color: #777;
  outline: none;
  background: none;
}

.portfolio-filter button:hover {
  border-bottom: 2px solid #f0326b;
  color: #000;
}

.portfolio-filter button.mixitup-control-active {
  border-bottom: 2px solid #f0326b;
  color: #000;
}

.portfolio-items-wrapper li {
  width: 32.33%;
}

.portfolio-block {
  position: relative;
  transition: .3s all;
  overflow: hidden;
}

.portfolio-block:before {
  content: '';
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(240, 50, 107, 0.9);
  transition: .3s all;
}

.portfolio-block:hover:before {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.portfolio-block:hover .caption h4 {
  transform: translate3d(0, 0, 0);
}

.portfolio-block:hover .caption {
  opacity: 1;
}

.portfolio-block:hover .caption .search-icon {
  opacity: 1;
}

.portfolio-block .caption {
  position: absolute;
  bottom: 10%;
  left: 10%;
  opacity: 0;
  z-index: 9;
  transition: all 0.3s ease-in-out;
}

.portfolio-block .caption h4 {
  font-size: 20px;
  font-weight: 700;
}

.portfolio-block .caption h4 a {
  color: #fff;
}

.portfolio-block .caption .search-icon {
  background: #f0326b;
  color: #fff;
  display: inline-block;
  padding: 4px 13px;
  font-size: 25px;
  border-radius: 30px;
  opacity: 0;
}

/*=================================================================
  Services section
==================================================================*/
.services .service-block {
  padding: 30px 20px;
}

@media (max-width: 480px) {
  .services .service-block {
    padding: 15px;
  }
}

.services .service-block .service-icon {
  margin-bottom: 15px;
}

.services .service-block i {
  font-size: 40px;
  color: #f0326b;
  background: linear-gradient(100deg, #f9643d, #fe2a77);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.services .service-block h3 {
  color: #333;
  font-size: 18px;
}

.services .service-block p {
  color: #777;
}

/*=================================================================
  Services section 2
==================================================================*/
@media (max-width: 480px) {
  .service-2 .p-0 {
    padding: 0 15px !important;
  }
}

@media (max-width: 768px) {
  .service-2 .p-0 {
    padding: 0 15px !important;
  }
}

@media (max-width: 400px) {
  .service-2 .p-0 {
    padding: 0 15px !important;
  }
}

.service-2 .service-item {
  background: #fff;
  border: 1px solid #eee;
  padding: 50px 20px;
  margin-right: -1px;
  margin-bottom: -1px;
  transition: all 0.3s ease 0s;
}

.service-2 .service-item .count {
  font-size: 80px;
  position: absolute;
  top: 0;
  left: 20px;
  color: #f3f1f1;
  font-weight: 700;
}

.service-2 .service-item i {
  font-size: 30px;
  display: inline-block;
  border-radius: 30px;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  height: 55px;
  line-height: 55px;
  margin-bottom: 20px;
  width: 55px;
  transition: all 0.3s ease 0s;
}

.service-list .block {
  background: #f0326b;
  color: #fff;
  padding: 30px;
}

.service-list .block i {
  font-size: 30px;
}

.service-list .block h3 {
  font-size: 22px;
  margin: 10px 0;
}

.service-list .block ul li {
  margin-bottom: 6px;
  color: #f9f9f9;
}

/*=================================================================
  Our skills
==================================================================*/
.team-skills .progress-block {
  margin-top: 40px;
}

.team-skills .progress-block span {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 14px;
}

.team-skills .progress-block .progress {
  box-shadow: none;
  border-radius: 0;
  height: 10px;
}

.team-skills .progress-block .progress .progress-bar {
  background: #f0326b;
  box-shadow: none;
}

.clients-logo-slider {
  margin-top: 50px;
}

/*=================================================================
  Step section
==================================================================*/
.development-steps .step {
  padding: 80px 20px;
}

.development-steps .step h4 {
  font-size: 20px;
  font-weight: 600;
}

.development-steps .step h4 i {
  font-size: 72px;
  font-weight: 500;
  margin-right: 10px;
}

.development-steps .step p {
  color: #cccccc;
  font-size: 14px;
  line-height: 22px;
}

.development-steps .step-1 {
  background-color: #f2f6f8;
}

.development-steps .step-1 h4 {
  color: #212121;
}

.development-steps .step-1 p {
  color: #838383;
}

.development-steps .step-2 {
  background-color: #f0326b;
}

.development-steps .step-2 h4, .development-steps .step-2 p {
  color: #fff;
}

.development-steps .step-3 {
  background: #df104e;
}

.development-steps .step-3 h4, .development-steps .step-3 p {
  color: #fff;
}

.development-steps .step-4 {
  background: #c70f46;
}

.development-steps .step-4 h4, .development-steps .step-4 p {
  color: #fff;
}

/*=================================================================
  Our Team
  ==================================================================*/
.team-member {
  background: #fff;
  margin-bottom: 15px;
}

.team-member:hover img {
  transform: scale(1.1);
}

.team-member:hover .mask {
  opacity: 1;
}

.team-member .member-content {
  padding: 25px 0;
}

.team-member .member-content h3 {
  font-size: 20px;
  margin: 0 0 5px;
  color: #333;
}

.team-member .member-content span {
  font-size: 14px;
  color: #555;
}

.team-member .member-content p {
  color: #777;
  margin-top: 10px;
  padding: 0 15px;
}

.team-member .member-content ul {
  text-align: center;
  position: relative;
}

.team-member .member-content ul li {
  margin-right: 7px;
}

.team-member .member-content ul li a {
  font-size: 18px;
  padding: 10px;
}

.team-member .member-content ul li a i {
  color: #f0326b;
  background: linear-gradient(100deg, #f9643d, #fe2a77);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.team-member .member-content ul li a::hover {
  background-color: #6cb670;
  border: 1px solid transparent;
}

.team-member .member-photo {
  overflow: hidden;
  position: relative;
}

.team-member .member-photo img {
  transition: all 0.6s ease 0s;
}

/*=================================================================
  Testimonial section
==================================================================*/
.testimonial {
  background: #f0326b;
  position: relative;
}

.testimonial .item i {
  font-size: 40px;
  color: #fff;
  margin-bottom: 30px;
  display: inline-block;
}

.testimonial .item .client-details {
  padding-bottom: 30px;
}

.testimonial .item .client-details p {
  font-size: 17px;
  color: #fff;
  font-weight: 200;
  padding: 0 10%;
}

.testimonial .item .client-meta {
  margin-top: 8px;
  color: #fff;
}

.testimonial .item .client-meta h4 {
  margin-bottom: 4px;
}

.testimonial .item .client-meta span {
  font-size: 12px;
}

.testimonial .item .client-thumb img {
  display: inline-block;
  border-radius: 100%;
  width: 90px;
  height: auto;
}

/*=================================================================
  Latest Posts
==================================================================*/
.post-item {
  background: #fff;
  margin-bottom: 40px;
}

.post-item .post-thumb {
  margin-right: 10px;
}

.post-item .post-thumb img {
  height: auto;
}

.post-item .post-title {
  margin-top: 20px;
}

.post-item .post-title h3 {
  font-size: 23px;
}

.post-item .post-title h3 a {
  color: #000;
}

.post-item .post-meta {
  font-size: 14px;
  color: #888;
}

.post-item .post-meta a {
  color: #000;
  margin: 3px 0;
}

.post-item .post-content p {
  color: #757575;
  margin: 10px 0;
}

.post-item .btn-main, .post-item .btn-main-sm {
  font-size: 12px;
  margin-top: 10px;
  padding: 8px 20px;
  margin: 15px 0;
}

/*=================================================================
  Single Blog Page
==================================================================*/
#blog-banner {
  padding-bottom: 100px;
  padding-top: 150px;
}

#blog-page {
  margin: 0 0 40px;
}

.post-excerpt {
  padding: 0 20px;
  margin-bottom: 60px;
}

.post-excerpt h3 a {
  color: #000;
}

.post-excerpt blockquote {
  line-height: 22px;
  margin: 20px 0;
  font-size: 16px;
}

.single-blog {
  background-color: #fff;
  margin-bottom: 50px;
  padding: 20px;
}

.blog-subtitle {
  font-size: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.next-prev {
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
  margin: 20px 0;
  padding: 25px 0;
}

.next-prev a {
  color: #000;
}

.next-prev a:hover {
  color: #f0326b;
}

.next-prev .prev-post i {
  margin-right: 10px;
}

.next-prev .next-post i {
  margin-left: 10px;
}

.social-profile ul li {
  margin: 0 10px 0 0;
  display: inline-block;
}

.social-profile ul li a {
  color: #4e595f;
  display: block;
  font-size: 16px;
}

.social-profile ul li a i:hover {
  color: #f0326b;
}

.comments-section {
  margin-top: 35px;
}

.author-about {
  margin-top: 40px;
}

.post-author {
  margin-right: 20px;
}

.post-author > img {
  border: 1px solid #dedede;
  max-width: 120px;
  padding: 5px;
  width: 100%;
}

.comment-list ul {
  margin-top: 20px;
}

.comment-list ul li {
  margin-bottom: 20px;
}

.comment-wrap {
  border: 1px solid #dedede;
  border-radius: 1px;
  margin-left: 20px;
  padding: 10px;
  position: relative;
}

.comment-wrap .author-avatar {
  margin-right: 10px;
}

.comment-wrap .media .media-heading {
  font-size: 14px;
  margin-bottom: 8px;
}

.comment-wrap .media .media-heading a {
  color: #f0326b;
  font-size: 13px;
}

.comment-wrap .media .comment-meta {
  font-size: 12px;
  color: #888;
}

.comment-wrap .media p {
  margin-top: 15px;
}

.comment-reply-form {
  margin-top: 80px;
}

.comment-reply-form input, .comment-reply-form textarea {
  height: 35px;
  border-radius: 0;
  box-shadow: none;
}

.comment-reply-form input:focus, .comment-reply-form textarea:focus {
  box-shadow: none;
  border: 1px solid #f0326b;
}

.comment-reply-form textarea, .comment-reply-form .btn-main, .comment-reply-form .btn-main-sm {
  height: auto;
}

/*=================================================================
  Footer section
==================================================================*/
.top-footer {
  background-color: #222222;
  border-top: 1px solid #404040;
  padding: 100px 0 80px;
}

.top-footer h3 {
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.top-footer p {
  font-size: 13px;
  line-height: 30px;
  color: #bdbdbd;
  font-weight: 300;
  padding-right: 20px;
}

.top-footer li a {
  font-size: 13px;
  line-height: 30px;
  color: #bdbdbd;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: capitalize;
  transition: color .3s;
  font-family: "Titillium Web", sans-serif;
  display: block;
}

.top-footer li a:hover {
  color: #f0326b;
}

.footer-bottom {
  text-align: center;
  background-color: #1b1b1b;
  border-top: 1px solid #2c2c2c;
  padding: 30px 0;
}

.footer-bottom h5 {
  font-size: 12px;
  line-height: 1;
  color: #888888;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 7px;
  margin-bottom: 3px;
}

.footer-bottom h6 {
  font-size: 11px;
  line-height: 15px;
  color: #888888;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 2px;
  margin-bottom: 0;
}

.footer-bottom p {
  color: #656565;
}

.footer-bottom a {
  color: #f0326b;
}

.subscribe-form .form-control {
  border-radius: 0;
}

.page-404 {
  height: 100vh;
}

.page-404 h1 {
  font-size: 200px;
}
